<script setup lang="ts">
import type { Picture } from '@yescapa-dev/ysc-api-js/legacy'
import type { APIVehiclePhotoImageTypePrefixed, CAMPER_TYPES, EnumToPrimitiveUnion } from '@yescapa-dev/ysc-api-js/modern'

interface AppCamperCardSlideShowProps {
  items: (APIVehiclePhotoImageTypePrefixed | Picture)[]
  vehicleType?: EnumToPrimitiveUnion<CAMPER_TYPES>
  camperId?: number
}

defineProps<AppCamperCardSlideShowProps>()

const { getTranslatedPictureType } = useGetTranslatedPictureType()
</script>

<template>
  <YscSlideShow
    :items="items"
    aspect="aspect-[16/10]"
    class="rounded"
  >
    <template #default="{ activeItem }">
      <template v-if="'type' in activeItem && activeItem.type === 'url'">
        <img
          :src="activeItem.path"
          class="aspect-[16/10]"
          :alt="vehicleType ? `${$t('commons.booking')} - ${useVehicleType({
            type: vehicleType.toLowerCase(),
          })} - ${'vehicle_image_type' in activeItem ? getTranslatedPictureType(activeItem.vehicle_image_type): ''} - #${camperId}` : undefined"
        >
      </template>
      <template v-else>
        <TwicImg
          :key="activeItem.id"
          :src="twicpicsPath(activeItem.path)"
          :alt="vehicleType ? `${$t('commons.booking')} - ${useVehicleType({
            type: vehicleType.toLowerCase(),
          })} - ${'vehicle_image_type' in activeItem ? getTranslatedPictureType(activeItem.vehicle_image_type): ''} - #${camperId}` : undefined"
          placeholder="maincolor"
          pre-transform="refit=auto(20p)"
          ratio="16/10"
        />
      </template>
    </template>
  </YscSlideShow>
</template>
